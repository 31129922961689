import React from "react";
import { globalHistory } from "@reach/router"
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Header, MainPost, PreTitle, Title,Subtitle, Tags, Date, Stats, SocialShare } from "../components/style/emo-post";
import ShareLink from 'react-twitter-share-link'

export default ({ data }) => {
  const post = data.markdownRemark;
  const windowGlobal = typeof window !== 'undefined' && window.location.hostname
  // var hostName = ""
  // if(windowGlobal !== 'undefined'){
  //   hostName=windowGlobal.location.hostname;
  // }


  {console.log(windowGlobal+globalHistory.location.pathname)}
  return (
    <Layout>
      <MainPost>
        <Header>
          <Subtitle><Link to={"/"}>&#x2190; Back</Link></Subtitle>
          <PreTitle>{post.frontmatter.category}</PreTitle>
          <Title>{post.frontmatter.title}</Title>
          <Subtitle>{post.frontmatter.subtitle}</Subtitle>
          <p>
            <strong>{post.frontmatter.projectname}</strong>{" "}
            <a href={post.frontmatter.project} target={"_blank"}>
              {post.frontmatter.project}
            </a>
          </p>
          <p>
            <strong>{post.frontmatter.demoname}</strong>{" "}
            <a href={post.frontmatter.demo} target={"_blank"}>
              {post.frontmatter.demo}
            </a>
          </p>
        </Header>
        <div
          className={"content"}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <footer>
          <Date>{post.frontmatter.date}</Date>
          <Tags>
            {post.frontmatter.tags.map((tag, index) => (
              <li key={index}>{tag}</li>
            ))}
          </Tags>
          <Stats>
            <SocialShare>
              <li>
                <ShareLink link={"https://"+windowGlobal+globalHistory.location.pathname}>
                  
                  {link => (
                    <a href={link} target="_blank">
                      <span className={"fab fa-twitter"}> </span>
                    </a>
                  )}
                </ShareLink>
              </li>
            </SocialShare>
          </Stats>
        </footer>
      </MainPost>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        tags
        projectname
        demoname
        category
        project
        demo
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`;
